import React from "react";
import type { GetSaleInventoryStateLegacyQuery } from "~/api/get-sale-inventory-state-legacy-query.generated.ts";
import getSaleInventoryStateLegacyQuery from "~/api/get-sale-inventory-state-legacy-query.graphql";
import type { InventoryStateReportProps } from "../inventory-shared/inventory-state-report.tsx";
import BaseItemsSoldReport from "../inventory-shared/base-items-sold-report.tsx";

function ItemsSoldReportLegacy(
	props: Pick<InventoryStateReportProps, "colours" | "containers">,
) {
	return (
		<BaseItemsSoldReport
			query={getSaleInventoryStateLegacyQuery}
			transformData={(data: GetSaleInventoryStateLegacyQuery) =>
				data.saleInventoryStateLegacy
			}
			{...props}
		/>
	);
}

export default ItemsSoldReportLegacy;
